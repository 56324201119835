<template>
  <div>
    <b-form-file
      v-model="screenshots"
      ref="refUploadScreenshots"
      plain
      accept=".jpg, .png, .jpeg"
      :hidden="true"
      :disabled="hasDisabled || listScreenShot.length >= uploadMax"
      @input="inputImageRenderer"
    />

    <div class="d-flex flex-wrap">
      <div
        v-for="(img, index) in listScreenShot"
        :key="index"
        class="img-screenshot mt-1"
      >
        <b-img
          :src="img"
          :data-testid="`image-screenshot-${index}`"
          alt="img uploaded"
        />
        <div class="gallery-item-info">
          <div
            :data-testid="`icon-preview-screenshot-${index}`"
            class="gallery-item-preview"
            @click="previewImgScreenshot(img)"
          >
            <feather-icon
              size="21"
              icon="EyeIcon"
            />
          </div>
          <feather-icon
            v-if="!hasDisabled"
            :data-testid="`icon-delete-screenshot-${index}`"
            size="21"
            icon="Trash2Icon"
            class="delete-screenshot cursor-pointer"
            @click="showConfirmDeleteModal(img)"
          />
        </div>
      </div>

      <div class="btn-add-screenshot mt-1">
        <div
          v-if="loadingFile"
          class="w-100 h-100 d-flex align-items-center justify-content-center cursor-not-allowed"
        >
          <b-spinner
            label="Loading..."
            variant="danger"
          />
        </div>
        <div
          v-else
          v-b-tooltip.v-light="{ id: 'tooltip-max-screenshot', title: !hasDisabled && listScreenShot.length >= uploadMax ? $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textUploadMax', { uploadMax }) : '' }"
          data-testid="item-add-store-screenshot"
          class="w-100 h-100 d-flex align-items-center justify-content-center"
          :class="!hasDisabled && listScreenShot.length < uploadMax ? 'cursor-pointer' : 'cursor-not-allowed btn-add-screenshot-disable'"
          @click="$refs.refUploadScreenshots.$el.click()"
        >
          <div
            class="text-center"
            style="padding: 0 4px"
          >
            <feather-icon
              size="32"
              icon="PlusCircleIcon"
              class="ic-add-screenshot"
            />
            <p class="mb-0 text-left text-add-screenshot">
              {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.formatImg') }}
            </p>
            <p class="mb-0 text-left text-add-screenshot">
              {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.maxSizeImg') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <show-screenshot-modal
      ref="open-screenshot-modal"
      :img="imgPreviewScreenshot"
    />
  </div>
</template>
<script>
import {
  BFormFile,
  BImg,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import ShowScreenshotModal from '@/components/ShowScreenshotModal.vue'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import Ripple from 'vue-ripple-directive'
import { toastification } from '@core/mixins/toast'

const { mapActions: mapActionsFile } = createNamespacedHelpers('file')

export default {
  components: {
    BFormFile,
    BImg,
    BSpinner,

    ShowScreenshotModal,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  mixins: [toastification],

  props: {
    listScreenShot: {
      type: Array,
      default: () => [],
    },

    hasDisabled: {
      type: Boolean,
      default: false,
    },

    uploadMax: {
      type: Number,
      default: 1,
    },
  },

  setup() {
    const {
      toggleCollapsed,
    } = useVerticalNavMenu()

    const refImage = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refImage, () => {})

    return {
      refImage,
      inputImageRenderer,
      toggleCollapsed,
    }
  },

  data() {
    return {
      screenshots: null,
      imgPreviewScreenshot: '',
    }
  },

  computed: {
    ...mapGetters({
      statusFile: 'file/status',
      fileUrl: 'file/fileUrl',
      loadingFile: 'file/loading',
      messageFile: 'file/message',
    }),
  },

  watch: {
    screenshots(val) {
      if (val) {
        this.uploadStoreScreenShots(val)
      }
    },
  },

  methods: {
    ...mapActionsFile(['uploadFile']),
    async uploadStoreScreenShots(file) {
      if (file) {
        await this.uploadFile(file)
        if (this.statusFile) {
          const screenshots = [...this.listScreenShot, this.fileUrl]
          this.$emit('changeScreenshot', screenshots)
          this.toastSuccess(this.$t('payment.toastUploadSuccess'))
        } else {
          this.toastFailure(this.messageFile)
        }
      }
    },

    previewImgScreenshot(img) {
      this.imgPreviewScreenshot = img
      this.$refs['open-screenshot-modal'].showModal()
    },

    deleteImgStoreScreenshot(img) {
      const screenshots = this.listScreenShot.filter(item => item !== img)
      this.$emit('changeScreenshot', screenshots)
    },

    showConfirmDeleteModal(img) {
      this.$bvModal
        .msgBoxConfirm(this.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.contentDeleteModal'), {
          title: this.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.titleDeleteModal'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textDelete'),
          cancelTitle: this.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.uploadStoreScreenshot.textCancel'),
          cancelVariant: 'outline-secondary',
          centered: true,
          id: 'confirm-delete-screenshot',
        })
        .then(async value => {
          if (value) {
            await this.deleteImgStoreScreenshot(img)
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>

  .label-add-store-screenshot {
    font-size: 16px;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .btn-example:hover {
    color: #ca1946;
  }

  .btn-add-screenshot {
    width: 124px;
    height: 100px;
    border: 1px dashed #ccc;
    border-radius: 8px;

    @media(max-width: 768px) {
      width: 47%;
    }

    .ic-add-screenshot {
      color: #9b9b9b;
      margin-bottom: 8px;
    }

    .text-add-screenshot {
      font-size: 10px;
      line-height: 14px;
      color: #484848;
    }
  }

  .btn-add-screenshot-disable {
    .ic-add-screenshot {
      color: #9b9b9b54;
    }

    .text-add-screenshot {
      color: #48484854;
    }
  }

  .img-screenshot {
    max-width: 124px;
    height: 100px;
    margin-right: 3%;
    width: max-content;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cccccc6e;
    border-radius: 8px;

    @media(max-width: 768px) {
      max-width: 47%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
    }

    &:last-child {
      margin: 0;
    }
  }

  .img-screenshot {
    width: 200px;
    position: relative;
    flex: 1 0 22rem;
    color: #fff;

    &:hover .gallery-item-info,
    &:focus .gallery-item-info {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .gallery-item-info {
      display: none;

      li {
        display: inline-block;
        font-size: 1.7rem;
        font-weight: 600;
      }

      .gallery-item-preview {
        cursor: pointer;
      }

      .gallery-item-delete {
        cursor: pointer;
      }

      .delete-screenshot {
        margin-left: 20px;
      }
    }
  }

</style>
