var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"primary-contact"},[_c('div',{staticClass:"top-content"},[_c('h4',{staticClass:"title-content"},[_vm._v(" "+_vm._s(_vm.hasStepGuide ? _vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textVerifyInformation') : _vm.$t('stepGuideControl.stepVerifyInfo.textSetupPrimaryContact'))+" ")]),_c('p',{staticClass:"subtitle-content"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.textProvidingSome'))+" ")]),(_vm.isWaitingVerifyInfo)?_c('div',{staticClass:"warning-message"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"alt":"alert","src":require('@/assets/images/common/ic-alert.svg')}}),_c('span',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.textYourInformationIsInReviewing'))+". "),_c('user-hint',{attrs:{"hide-icon":true}})],1)])]):_vm._e(),(_vm.contentRejectReason)?_c('div',{staticClass:"warning-message error-message"},[_c('div',{staticClass:"d-flex align-items-center mb-50"},[_c('img',{staticStyle:{"margin-right":"8px"},attrs:{"alt":"alert","src":require('@/assets/images/common/ic-warning-red.svg')}}),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.textTheProcessForVerifying'))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v("\"")]),_c('div',{staticClass:"reject-reason",domProps:{"innerHTML":_vm._s(_vm.contentRejectReason)}}),_c('span',[_vm._v("\"")])]),_c('user-hint',{attrs:{"hide-icon":true}})],1):_vm._e()]),_c('div',{staticClass:"form-primary-contact"},[_c('b-card-text',{staticClass:"font-weight-600"},[_vm._v(" *"+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.noteForm'))+" ")]),_c('validation-observer',{ref:"formPrimaryContact",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",staticClass:"repeater-form"},[_c('b-form-group',{staticClass:"group-input"},[_c('phone-input',{class:_vm.hasDisabled ? 'bg-input-disable' : '',attrs:{"phone-data":_vm.phoneData,"disabled":_vm.hasDisabled,"is-error-phone-number":_vm.isErrorPhoneNumber},on:{"update:phoneData":function($event){_vm.phoneData=$event},"update:phone-data":function($event){_vm.phoneData=$event}}})],1),_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"disabled":_vm.hasDisabled},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.checkBoxPhoneNumber'))+" ")]),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label",attrs:{"for":"zaloNumber"}},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelZaloNumber'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('validation-provider',{attrs:{"id":"zaloNumber","rules":"verifyZaloNumber|required","name":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelZaloNumber')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"zalo-number","name":"zaloNumber","placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderZaloNumber'),"state":errors.length > 0 ? false : null,"disabled":_vm.hasDisabled || _vm.checked},model:{value:(_vm.zaloNumber),callback:function ($$v) {_vm.zaloNumber=$$v},expression:"zaloNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.hasStepGuide)?_c('div',{staticClass:"product-link-form"},[_c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('div',{staticClass:"bg-white box",class:{
                    'form-add-product-link': !_vm.$device.mobile
                  }},[_c('h4',{staticClass:"mb-2 font-medium title-add-product-link"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textWhichProduct'))+" ")]),_c('b-row',[_c('b-col',{staticClass:"input-form",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"industry-tag"},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"industry","name":"industry","placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderIndustry'),"state":errors.length > 0 ? false : null,"disabled":_vm.hasDisabled},model:{value:(_vm.businessModel),callback:function ($$v) {_vm.businessModel=$$v},expression:"businessModel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"input-form",attrs:{"cols":"12"}},[(!_vm.currentProductlink && !_vm.currentShopCode)?_c('b-card-text',{staticClass:"font-weight-600 distance-form",class:_vm.isErrorProduct ? 'text-danger' : ''},[_vm._v(" *"+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textPleaseEnterOneOfTwo'))+" ")]):_vm._e(),_c('b-form-group',{staticClass:"product-tag mb-0",attrs:{"label-for":"tags-validation"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelProductUrl'))+" "),(!!_vm.currentProductlink)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()])]),_c('validation-provider',{attrs:{"id":"product-link","name":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelProductUrl'),"rules":{
                            url: _vm.url,
                            productLink: _vm.productLink,
                            required : !!_vm.currentProductlink
                          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height input-product-link",attrs:{"id":"verify-product-link","data-testid":"verify-product-link","name":"product link","placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderProductUrl'),"tabindex":1,"state":errors.length > 0 ? false : null,"disabled":_vm.hasDisabled},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":_vm.changeProduct},model:{value:(_vm.productsData.productURL),callback:function ($$v) {_vm.$set(_vm.productsData, "productURL", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"productsData.productURL"}})],1),_c('div',{staticClass:"notices-product-link",class:{
                              'input-invalid': errors.length > 0 && !_vm.isFocus,
                              'input-focus': _vm.isFocus
                            }},[_c('span',{staticClass:"title-notices"},[_vm._v("🔥🔥🔥 "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textNotices'))+":")]),_c('ul',{staticClass:"list-notices"},[_c('li',{staticClass:"item-notices"},[_c('span',{staticClass:"content-notices"},[_vm._v(_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.firstNotices')))])]),_c('li',{staticClass:"item-notices"},[_c('span',{staticClass:"content-notices"},[_vm._v(_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentSecondNotices'))+" "),_c('a',{staticClass:"text-blue",attrs:{"href":"https://www.google.com","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("https://www.google.com")]),_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIsDifferentFrom'))+" "),_c('a',{staticClass:"text-blue",attrs:{"href":"https://google.com","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("https://google.com")]),_vm._v(". "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textInCase')))])]),_c('li',{staticClass:"item-notices"},[_c('span',{staticClass:"content-notices"},[_vm._v(_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentFinalNotices')))])])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{staticClass:"m-0 country-tag mt-2"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelTikTokShopCode'))+" "),(!!_vm.currentShopCode)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),_c('span',{staticClass:"font-small-2 text-placeholder"},[_vm._v(" ("+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIfYouUseTikTokShop'))+") ")])])]),_c('validation-provider',{attrs:{"id":"shopCode","name":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelTikTokShopCode'),"rules":{
                              required : !!_vm.currentShopCode
                            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"id":"shop-code","name":"shopCode","placeholder":_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeHolderTikTokShopCode'),"state":errors.length > 0 ? false : null,"disabled":_vm.hasDisabled},on:{"input":_vm.changeProduct},model:{value:(_vm.shopCode),callback:function ($$v) {_vm.shopCode=$$v},expression:"shopCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('p',{staticClass:"font-14 text-notes"},[_vm._v(" *"+_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentNotesStep',{platform: ("" + _vm.platformName)}))+" ")])])],1)],1)])],1)],1):_vm._e(),_c('div',{staticClass:"btn-control"},[_c('btn-loading',{attrs:{"variant-convert":"btn-submit","type":"submit","pill":"","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.goNext(invalid)}}},[(_vm.isWaitingVerifyInfo || _vm.isInfoVerified)?_c('span',[_vm._v(_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textContinue')))]):_c('span',[_vm._v(_vm._s(_vm.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textSubmitAndContinue')))])])],1)],1)]}}])})],1),_c('user-hint')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }