/* eslint-disable camelcase */
import { getCookie } from '@/libs/common'
import authMixin from './authMixin'

export default {
  mixins: [authMixin],
  computed: {},

  methods: {
    gaTrackingUserStep(step) {
      const source = getCookie('utm_source')
      const medium = getCookie('utm_medium')
      const dataLayer = {
        event: `user_step_${step}`,
        user_email: this.userEmail,
        user_id: this.userEmail,
        userId: this.userEmail,
        user_phoneNumber: this.phoneNumber(),
        user_step_number: step,
        ...(source && { utm_source: source }),
        ...(medium && { utm_medium: medium }),
      }

      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push(dataLayer)
    },

    gaTrackingUserAddFund(amount, payment_method) {
      const source = getCookie('utm_source')
      const medium = getCookie('utm_medium')
      const dataLayer = {
        event: 'user_add_fund',
        user_email: this.userEmail,
        user_id: this.userEmail,
        userId: this.userEmail,
        user_phoneNumber: this.phoneNumber(),
        user_step_number: this.userStep,
        add_fund_amount: amount,
        payment_method,
        ...(source && { utm_source: source }),
        ...(medium && { utm_medium: medium }),
      }

      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push(dataLayer)
    },

    gaTrackingUserAddFundSuccess(amount, payment_method) {
      const source = getCookie('utm_source')
      const medium = getCookie('utm_medium')
      const dataLayer = {
        event: 'user_add_fund_success',
        user_email: this.userEmail,
        user_id: this.userEmail,
        userId: this.userEmail,
        user_phoneNumber: this.phoneNumber(),
        user_step_number: this.userStep,
        add_fund_amount: amount,
        payment_method,
        ...(source && { utm_source: source }),
        ...(medium && { utm_medium: medium }),
      }

      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push(dataLayer)
    },
  },
}
