<template>
  <div>
    <div class="primary-contact">
      <div class="top-content">
        <h4 class="title-content">
          {{ hasStepGuide ? $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textVerifyInformation') : $t('stepGuideControl.stepVerifyInfo.textSetupPrimaryContact') }}
        </h4>
        <p class="subtitle-content">
          {{ $t('stepGuideControl.stepVerifyInfo.textProvidingSome') }}
        </p>
        <div
          v-if="isWaitingVerifyInfo"
          class="warning-message"
        >
          <div class="d-flex align-items-center">
            <img
              style="margin-right: 8px"
              alt="alert"
              :src="require('@/assets/images/common/ic-alert.svg')"
            >
            <span class="mb-0">
              {{ $t('stepGuideControl.stepVerifyInfo.textYourInformationIsInReviewing') }}.
              <user-hint :hide-icon="true" />
            </span>
          </div>
        </div>

        <div
          v-if="contentRejectReason"
          class="warning-message error-message"
        >
          <div class="d-flex align-items-center mb-50">
            <img
              style="margin-right: 8px"
              alt="alert"
              :src="require('@/assets/images/common/ic-warning-red.svg')"
            >
            <p class="mb-0">
              {{ $t('stepGuideControl.stepVerifyInfo.textTheProcessForVerifying') }}
            </p>
          </div>
          <div class="d-flex">
            <span>"</span>
            <div
              class="reject-reason"
              v-html="contentRejectReason"
            />
            <span>"</span>
          </div>
          <user-hint :hide-icon="true" />
        </div>
      </div>
      <div class="form-primary-contact">
        <b-card-text class="font-weight-600">
          *{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.noteForm') }}
        </b-card-text>
        <validation-observer
          ref="formPrimaryContact"
          v-slot="{ invalid }"
        >
          <b-form
            ref="form"
            class="repeater-form"
          >
            <b-form-group class="group-input">
              <phone-input
                :class="hasDisabled ? 'bg-input-disable' : ''"
                :phone-data.sync="phoneData"
                :disabled="hasDisabled"
                :is-error-phone-number="isErrorPhoneNumber"
              />
            </b-form-group>

            <b-form-checkbox
              v-model="checked"
              class="mb-2"
              :disabled="hasDisabled"
            >
              {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.checkBoxPhoneNumber') }}
            </b-form-checkbox>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="zaloNumber"
                >
                  {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelZaloNumber') }} <span class="text-danger">*</span>
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="zaloNumber"
                rules="verifyZaloNumber|required"
                :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelZaloNumber')"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="zaloNumber"
                    id="zalo-number"
                    name="zaloNumber"
                    class="input-height"
                    :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderZaloNumber')"
                    :state="errors.length > 0 ? false : null"
                    :disabled="hasDisabled || checked"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div
              v-if="hasStepGuide"
              class="product-link-form"
            >
              <validation-observer ref="formValidation">
                <b-form>
                  <div
                    class="bg-white box"
                    :class="{
                      'form-add-product-link': !$device.mobile
                    }"
                  >
                    <h4 class="mb-2 font-medium title-add-product-link">
                      {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textWhichProduct') }}
                    </h4>

                    <b-row>
                      <b-col
                        cols="12"
                        class="input-form"
                      >
                        <b-form-group class="industry-tag">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry')"
                          >
                            <div class="d-flex justify-content-between">
                              <label class="form-label">
                                {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIndustry') }} <span class="text-danger">*</span>
                              </label>
                            </div>
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : null"
                            >
                              <b-form-input
                                v-model="businessModel"
                                id="industry"
                                name="industry"
                                class="input-height"
                                :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderIndustry')"
                                :state="errors.length > 0 ? false : null"
                                :disabled="hasDisabled"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        class="input-form"
                      >

                        <b-card-text
                          v-if="!currentProductlink && !currentShopCode"
                          class="font-weight-600 distance-form"
                          :class="isErrorProduct ? 'text-danger' : ''"
                        >
                          *{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textPleaseEnterOneOfTwo') }}
                        </b-card-text>

                        <b-form-group
                          class="product-tag mb-0"
                          label-for="tags-validation"
                        >
                          <div class="d-flex justify-content-between">
                            <label class="form-label">
                              {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelProductUrl') }}
                              <span
                                v-if="!!currentProductlink"
                                class="text-danger"
                              >*</span>
                            </label>
                          </div>
                          <validation-provider
                            #default="{ errors }"
                            id="product-link"
                            :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelProductUrl')"
                            :rules="{
                              url,
                              productLink,
                              required : !!currentProductlink
                            }"
                          >
                            <b-input-group
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid' : null"
                            >
                              <b-form-input
                                v-model.trim="productsData.productURL"
                                id="verify-product-link"
                                data-testid="verify-product-link"
                                name="product link"
                                class="input-height input-product-link"
                                :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeholderProductUrl')"
                                :tabindex="1"
                                :state="errors.length > 0 ? false : null"
                                :disabled="hasDisabled"
                                @focus="onFocus"
                                @blur="onBlur"
                                @input="changeProduct"
                              />
                            </b-input-group>
                            <div
                              class="notices-product-link"
                              :class="{
                                'input-invalid': errors.length > 0 && !isFocus,
                                'input-focus': isFocus
                              }"
                            >
                              <span class="title-notices">🔥🔥🔥 {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textNotices') }}:</span>
                              <ul class="list-notices">
                                <li class="item-notices">
                                  <span
                                    class="content-notices"
                                  >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.firstNotices') }}</span>
                                </li>
                                <li class="item-notices">
                                  <span
                                    class="content-notices"
                                  >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentSecondNotices') }}
                                    <a
                                      href="https://www.google.com"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      class="text-blue"
                                    >https://www.google.com</a>
                                    {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIsDifferentFrom') }}
                                    <a
                                      href="https://google.com"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      class="text-blue"
                                    >https://google.com</a>. {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textInCase') }}</span>
                                </li>
                                <li class="item-notices">
                                  <span
                                    class="content-notices"
                                  >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentFinalNotices') }}</span>
                                </li>
                              </ul>
                            </div>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="country"
                        >
                          <b-form-group class="m-0 country-tag mt-2">
                            <div class="d-flex justify-content-between">
                              <label class="form-label">
                                {{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelTikTokShopCode') }}
                                <span
                                  v-if="!!currentShopCode"
                                  class="text-danger"
                                >*</span>
                                <span class="font-small-2 text-placeholder">
                                  ({{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textIfYouUseTikTokShop') }})
                                </span>
                              </label>
                            </div>
                            <validation-provider
                              #default="{ errors }"
                              id="shopCode"
                              :name="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelTikTokShopCode')"
                              :rules="{
                                required : !!currentShopCode
                              }"
                            >
                              <b-input-group
                                class="input-group-merge"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                              >
                                <b-form-input
                                  v-model="shopCode"
                                  id="shop-code"
                                  name="shopCode"
                                  class="input-height"
                                  :placeholder="$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.placeHolderTikTokShopCode')"
                                  :state="errors.length > 0 ? false : null"
                                  :disabled="hasDisabled"
                                  @input="changeProduct"
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <b-row class="mt-2">
                      <b-col>
                        <p class="font-14 text-notes">
                          *{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.contentNotesStep',{platform: `${ platformName }`}) }}
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </b-form>
              </validation-observer>
            </div>

            <div class="btn-control">
              <btn-loading
                variant-convert="btn-submit"
                type="submit"
                pill
                :loading="loading"
                @click.prevent="goNext(invalid)"
              >
                <span
                  v-if="isWaitingVerifyInfo || isInfoVerified"
                >{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textContinue') }}</span>
                <span v-else>{{ $t('stepGuideControl.stepVerifyInfo.formPrimaryContact.textSubmitAndContinue') }}</span>
              </btn-loading>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <user-hint />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup,
  BForm,
  BFormInput,
  BInputGroup,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  required,
  fbUrl,
  telegramLength,
  valExcludeUrl,
  url,
  productLink,
  verifyPhoneNumberWhatApp,
  verifyZaloNumber,
  verifyPhoneNumberVN,
} from '@validations'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { toastification } from '@core/mixins/toast'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import envMixin from '@/mixins/envMixin'
import { PRODUCT_STATUS } from '@/constants'
import authMixin from '@/mixins/authMixin'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import UserHint from '@/components/UserHint.vue'
import PhoneInput from '@/views/auth/PhoneInput.vue'

const { mapActions } = createNamespacedHelpers('auth')
const { mapActions: mapActionsFile } = createNamespacedHelpers('file')

export default {
  components: {
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroup,
    BtnLoading,
    BCardText,
    BRow,
    BCol,
    BFormCheckbox,
    UserHint,
    PhoneInput,

    // validations
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [
    heightTransition,
    toastification,
    stepGuideMixin,
    envMixin,
    authMixin,
  ],

  setup() {
    const refImage = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refImage, () => {})

    return {
      refImage,
      inputImageRenderer,
    }
  },

  data() {
    return {
      zaloNumber: '',
      checked: false,
      businessModel: '',
      shopCode: null,
      currentProductlink: '',
      currentShopCode: '',

      phoneData: {
        countryAbbr: 'vn',
        countryCode: '',
        phone: '',
        isValid: true,
        isRequired: false,
      },
      isErrorPhoneNumber: false,
      isErrorProduct: false,

      // validate
      required,
      fbUrl,
      telegramLength,
      valExcludeUrl,
      isFocus: false,
      url,
      productLink,
      verifyPhoneNumberWhatApp,
      verifyZaloNumber,
      verifyPhoneNumberVN,

      listProductIndustry: [
        {
          label: 'Thời trang',
          value: 'Thời trang',
        },
        {
          label: 'Mỹ phẩm / Làm đẹp',
          value: 'Mỹ phẩm / Làm đẹp',
        },
        {
          label: 'Mẹ và bé',
          value: 'Mẹ và bé',
        },
        {
          label: 'Công nghệ / Phụ kiện',
          value: 'Công nghệ / Phụ kiện',
        },
        {
          label: 'Nội ngoại thất',
          value: 'Nội ngoại thất',
        },
        {
          label: 'Điện máy',
          value: 'Điện máy',
        },
        {
          label: 'Xe cộ / Máy móc / Vật tư / Linh kiện',
          value: 'Xe cộ / Máy móc / Vật tư / Linh kiện',
        },
        {
          label: 'Sách / Văn phòng phẩm',
          value: 'Sách / Văn phòng phẩm',
        },
        {
          label: 'Quà tặng / Sở thích',
          value: 'Quà tặng / Sở thích',
        },
        {
          label: 'Nhà hàng',
          value: 'Nhà hàng',
        },
        {
          label: 'Cafe',
          value: 'Cafe',
        },
        {
          label: 'Thực phẩm tươi sống',
          value: 'Thực phẩm tươi sống',
        },
        {
          label: 'Siêu thị',
          value: 'Siêu thị',
        },
        {
          label: 'Nhà thuốc',
          value: 'Nhà thuốc',
        },
        {
          label: 'Dịch vụ',
          value: 'Dịch vụ',
        },
        {
          label: 'Khác',
          value: 'Khác',
        },
        {
          label: 'Tôi chưa biết kinh doanh gì',
          value: 'Tôi chưa biết kinh doanh gì',
        },
      ],

      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },
    }
  },

  computed: {
    ...mapGetters({
      status: 'auth/status',
      loading: 'auth/loading',
      message: 'auth/message',
      user: 'auth/user',
      statusFile: 'file/status',
      fileUrl: 'file/fileUrl',
      loadingFile: 'file/loading',
      messageFile: 'file/message',
    }),

    contactInfo() {
      return this.user?.data?.contactInfo
    },

    contentRejectReason() {
      return this.user?.data?.contactInfo?.verification?.rejectedReason
    },

    hasDisabled() {
      return this.isWaitingVerifyInfo || this.isInfoVerified
    },

    validPhoneInput() {
      return invalid => invalid || !this.phoneData.phone || !this.phoneData.isValid
    },
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo?.data) {
          if (userInfo?.data?.contactInfo) {
            this.zaloNumber = userInfo?.data?.contactInfo?.zalo || ''

            const [
              countryAbbr,
              countryCode,
              phoneNum,
            ] = userInfo?.data?.contactInfo?.phone?.split('|')

            this.phoneData.phone = phoneNum
            this.phoneData.countryAbbr = countryAbbr
            this.phoneData.countryCode = countryCode

            if (this.zaloNumber === `+${this.phoneData.countryCode} ${this.phoneData.phone}`) {
              this.checked = true
            }
          }

          this.businessModel = userInfo?.data?.industry
          this.shopCode = userInfo?.data?.shopCode
          this.currentShopCode = userInfo?.data?.shopCode

          const productLinks = userInfo?.data?.products?.productURLs
          if (productLinks) {
            const convertedUrls = Object.keys(productLinks).map(value => ({
              productUrl: value,
              ...productLinks[value],
            }))

            const arrSortDate = convertedUrls.sort(
              (a, b) => new Date(b.requestedAt) - new Date(a.requestedAt),
            )

            const productLinkReviewing = arrSortDate.find(
              item => item.status === PRODUCT_STATUS.REVIEWING,
            )
            this.currentProductlink = productLinkReviewing?.productUrl || arrSortDate?.[0].productUrl
            this.productsData.productURL = productLinkReviewing?.productUrl || arrSortDate?.[0].productUrl
          }
        }
      },
      deep: true,
      immediate: true,
    },

    phoneData: {
      handler(val) {
        if (this.checked) {
          this.zaloNumber = `+${val.countryCode} ${val.phone}`
        }
        this.isErrorPhoneNumber = false
      },
      deep: true,
      immediate: true,
    },

    checked(val) {
      if (val) {
        this.zaloNumber = `+${this.phoneData.countryCode} ${this.phoneData.phone}`
      } else {
        this.zaloNumber = ''
      }
    },
  },

  methods: {
    ...mapActions(['updateAccount', 'getAccountInfo']),
    ...mapActionsFile(['uploadFile']),

    onFocus() {
      this.isFocus = true
    },

    onBlur() {
      this.isFocus = false
    },

    changeProduct() {
      if (!(this.productsData.productURL || this.shopCode)) {
        this.isErrorProduct = true
      } else {
        this.isErrorProduct = false
      }
    },

    goNext(invalid) {
      this.changeProduct()
      if (this.phoneData.phone === '') {
        this.isErrorPhoneNumber = true
      } else {
        this.isErrorPhoneNumber = false
      }
      if (this.isInfoVerified || this.isWaitingVerifyInfo) {
        this.$router.push('/waiting-verify-information')
      } else {
        this.validationForm(invalid)
      }
    },

    openLiveChat() {
      if (window.fcWidget) {
        window.fcWidget.open()
      }
    },

    async validationForm(invalid) {
      const success = await this.$refs.formPrimaryContact.validate()
      if (success && !this.validPhoneInput(invalid) && !this.isErrorProduct) {
        const params = {
          contactInfo: {
            phone: `${this.phoneData.countryAbbr}|${this.phoneData.countryCode}|${this.phoneData.phone}`,
            zalo: this.zaloNumber,
          },
          ...(this.shopCode && { shopCode: this.shopCode }),
          ...(this.businessModel && { industry: this.businessModel }),
          ...(this.productsData.productURL
            && this.hasStepGuide && { products: this.productsData }),
        }

        await this.updateAccount(params)
        if (this.status) {
          this.getAccountInfo()
          this.$refs.formPrimaryContact.reset()
          if (this.setupGuideStep) {
            this.$router.push({ name: 'waiting-verify-information' })
          }
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.input-height {
  .vs__dropdown-toggle {
    padding: 0.438rem 1rem !important;

    .vs__actions {
      padding: 0;
    }

    .vs__selected,
    .vs__search,
    .vs__selected-options {
      margin: 0;
      padding: 0;
    }
  }
}

.product-tag {
  .b-form-tags {
    padding: 0;
    border: none;
  }

  .card {
    border-radius: 0.625rem;
  }

  .card-body {
    padding: 0.86rem 1rem;
  }
}

.industry-tag {
  .b-form-tags {
    padding: 0;
    border: none;
  }

  .vs__selected {
    background: rgba(22, 33, 62, 0.05);
    color: rgba(22, 33, 62, 0.7);
  }

  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.05);
    color: rgba(22, 33, 62, 0.7);
  }
}

.input-height {
  .vs__dropdown-toggle {
    padding: 5.09px 0 6.5px 0;
  }
}

.bg-input-disable {
  .int-tel-input {
    background-color: #efefef;
    color: #16213e;
  }
}

.phone-number-error {
  .int-tel-input {
    input {
      border: 1px solid #e11c4e;
    }
  }
}
</style>

<style lang="scss" scoped>
.upload {
  cursor: pointer;
  background: #f5f5f7;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-bottom: .5px solid rgba(0, 0, 0, 0.1);
  border-radius: 14px 14px 0 0;
  padding: 32px 0px;
  justify-content: center;

  img {
    max-width: 15px;
    margin-right: 5px;
  }
}

.description-screenshot {
  border-radius: 0 0 14px 14px;
  background: #f5f5f7;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-top: .5px solid rgba(0, 0, 0, 0.1);
  padding: 32px;
  margin-bottom: 1rem;
}

.top-notices {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &__content {
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.input-height {
  height: 48px;
}

.primary-contact {
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  border-radius: var(--border-radius-base);
  padding: 24px;

  @media (max-width: 767px) {
    padding: 20px;
  }

  .repeater-form {
    overflow: hidden;
    transition: 0.35s height;
  }

  .top-content {
    margin-bottom: 32px;
  }

  .input-height {
    height: 48px;
  }

  .btn-control {
    display: flex;
    justify-content: end;
    align-items: center;

    .btn-decline,
    .btn-continue {
      width: max-content;
      font-size: 14px;
      margin: 10px 6px;
    }

    .btn-decline {
      background: rgba(225, 28, 78, 0.1) !important;
      color: #e11c4e !important;
      border: none !important;
    }
  }
}

.content-reject {
  width: 100%;
  background-color: rgba(225, 28, 78, 0.5);
  padding: 1rem;
  border-radius: 20px;
}

.warning-message,
.error-message {
  margin-top: 10px;
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}

.error-message {
  background: rgba(225, 28, 78, 0.1);
}

.input-product-link {
  border-radius: 12px 12px 0px 0px !important;
}

.notices-product-link {
  border: 1px solid #e8e9ec;
  border-radius: 0px 0px 12px 12px;
  border-top: none;

  padding: 14px;

  .title-notices {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }

  .list-notices {
    margin-top: 10px;
    list-style-type: unset;
    padding-left: 1rem;

    .item-notices {
      margin-bottom: 8px;

      .content-notices {
        color: rgba(0, 0, 0, 0.7);
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.input-invalid {
    border-color: #e11c4e;
  }

  &.input-focus {
    border-color: #16213e;
    box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
  }
}

.guide-icon {
  top: 35px;
  right: 23px;
}

.disabled-upload {
  cursor: no-drop;
}

.distance-form {
  margin-bottom: 8px;
}

.img-preview {
  width: 200px;
  position: relative;
  flex: 1 0 22rem;
  color: #fff;
  cursor: pointer;

  img {
    width: 100%;
  }

  &:hover .gallery-item-info,
  &:focus .gallery-item-info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .gallery-item-info {
    display: none;

    li {
      display: inline-block;
      font-size: 1.7rem;
      font-weight: 600;
    }

    .gallery-item-preview {
      margin-right: 2.2rem;
    }
  }
}

.fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .img-full {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 3rem;
    color: white;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
}
</style>
